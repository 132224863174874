import { Container, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import OptionsColumn from '#/components/pages/SituationCheck/options-column';
import {
  LIFE_SITUATIONS,
  PROVISION_TOPICS,
} from '#/components/pages/PensionProvision/onboarding/data';
import IntroHead from '#/components/shared/IntroHead';
import useLocales from '#/hooks/useLocales';
import useAuth from '#/lib/hooks/useAuth';
import PaxDisclaimerModal from '#/components/shared/PaxDisclaimerModal';
import useLocalStorage from '#/lib/hooks/useLocalStorage';

export default function SituationCheck() {
  const location = useLocation();
  const { translate } = useLocales();
  const { isAuthenticated } = useAuth();

  const registrationState = location.state?.registration;

  const introTitle = registrationState
    ? 'pensionProvision.title'
    : 'pensionProvision.lifesituationCheck.title';

  const introDescription = registrationState
    ? ''
    : 'pensionProvision.lifesituationCheck.description';

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 6 }}>
        <IntroHead
          title={String(translate(introTitle))}
          description={String(translate(introDescription))}
        />
        {!isAuthenticated && (
          <Typography
            variant="body2"
            gutterBottom
            textAlign="center"
            color="primary"
            sx={{ marginBottom: 6 }}
          >
            {String(translate('pensionProvision.alreadyHaveAcc'))} &nbsp;
            <Link
              to="/auth/login"
              style={{
                color: '#3366FF',
              }}
            >
              {String(translate('pensionProvision.signIn'))}
            </Link>
          </Typography>
        )}
        {registrationState && !isAuthenticated && (
          <Typography
            variant="body2"
            gutterBottom
            textAlign="center"
            color="primary"
            sx={{ marginBottom: 6 }}
          >
            {String(translate('pensionProvision.alreadyHaveAcc'))} &nbsp;
            <Link
              to="/auth/login"
              style={{
                color: '#3366FF',
              }}
            >
              {String(translate('pensionProvision.signIn'))}
            </Link>
          </Typography>
        )}
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
          sx={{ marginTop: 10, gap: 2 }}
        >
          <OptionsColumn
            title="pensionProvision.onboarding.stepThree.lifeSituations"
            options={LIFE_SITUATIONS}
            inverse
          />
          <OptionsColumn
            title="pensionProvision.onboarding.stepThree.provisionTopics"
            options={PROVISION_TOPICS}
          />
        </Stack>
      </Container>
    </>
  );
}
